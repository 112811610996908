import React, { useEffect, useState } from 'react';
import CartSummary from './component/CartSummary';
import PaymentMethod from './component/PaymentMethod';
import AppDrawer from '../layout/app-drawer/AppDrawer';
import Header from '../layout/header/Header';
import * as Styles from './Checkout.style';
import PlaceOrder from './component/PlaceOrder';
import CartLogin from './component/CartLogin';
import { Formik } from 'formik';
import { initialValuesLogin, validationSchemaLogin } from '../vs/util';
import { scrollToSection } from 'utils';
import CustomButton from 'components/common/custom-button';
import { useMutation } from 'react-query';
import { mutationProps } from 'utils/custom-hook';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from 'constants/env';
import { getLocalStorageValues } from 'constants/local-storage';
import { Message } from 'components/alert/Message';
import { useTranslation } from 'react-i18next';
import { CHECKOUT_VS_ID, CHECKOUT_VS } from './queries';
import { validation } from 'utils/password-validation-tooltip';
import { Base64 } from 'js-base64';
import { CustomHook } from 'utils/custom-hook';

const Checkout = ({ isAppDrawerOpen, queryClient }) => {
  const [selected, setSelected] = useState(100);
  const [isLoad, setIsLoad] = useState(false);
  const handleClick = (index) => setSelected(index);
  const { promoCode } = getLocalStorageValues();
  const { t } = useTranslation();
  const { logout } = CustomHook();

  useEffect(() => {
    setSelected(100);
  }, []);

  let title = selected == 100 ? 'public/checkout.string23' : 'public/checkout.string32';
  const { remember_cart_login } = getLocalStorageValues();
  const { mutate: placeOrder, isLoading } = useMutation(CHECKOUT_VS);
  const cartVs = useSelector((state) => state.vsCart.vs_deploy);

  const handleOrder = async (values) => {
    let payload = { action: 'new' };
    if (remember_cart_login?.userName) {
      payload['username'] = remember_cart_login?.userName;
      payload['token'] = remember_cart_login?.token;
    } else {
      payload['email'] = values.emailAddress;
      payload['password'] = Base64.btoa(values.password);
    }
    payload['promocode'] = promoCode.replace('/', '');

    await placeOrder(
      { ...payload },
      mutationProps(
        false,
        {
          isSuccess: false,
          onSuccess: async (res) => {
            try {
              const promises = [];
              for (const key in cartVs) {
                let object = cartVs[key];
                const promise = CHECKOUT_VS_ID({
                  id: res.order_id,
                  action: 'append',
                  size: object.selected_size.id,
                  region: object.selected_region.id,
                  image: object.selected_image.id,
                  app: object.app,
                  backup_plan: object.backup,
                  term: object.term,
                  promocode: promoCode.replace('/', ''),
                });
                promises.push(promise);
              }
              await Promise.all(promises);
              let stripeApi = `/public/checkout/vs/${res.order_id}/stripe`;
              let paypalApi = `/public/checkout/vs/${res.order_id}/paypal`;
              let apiUrl = selected == 1 ? paypalApi : stripeApi;
              const { data } = await axios.get(`${BASE_URL}${apiUrl}`);
              window.location.href = data.payment_url;
            } catch (error) {
              Message.error(t(`${error.response.data.error || error.response.data.status}`), error ? t(error) : '');
            }
          },
          onError: async (err) => {
            if (err.response?.data?.status == 'apierror_session_timed_out') {
              Message.error(t('alert25'), t('error'));
              await localStorage.removeItem('remember_cart_login');
              setSelected(100);
              scrollToSection('cart-user-login');
            } else {
              Message.error(t(`${err.response?.data?.error || err.response?.data?.status}`), t('error'));
            }
          },
        },
        t,
      ),
    );
  };

  const handleLogout = async () => {
    setSelected(100);
    setIsLoad(true);
    await logout();
    await localStorage.removeItem('remember_cart_login');
    await localStorage.removeItem('userName');
    setIsLoad(false);
  };

  return (
    <Styles.MemberNavigatorSC>
      <AppDrawer isAppDrawerOpen={isAppDrawerOpen} />
      <Styles.PageHeaderBodyContainer>
        <Header queryClient={queryClient} />
        <Formik initialValues={initialValuesLogin} validationSchema={validationSchemaLogin}>
          {(formik) => (
            <Styles.PageContainer>
              <Styles.PageContent>
                <CartSummary />
                <CartLogin isLoading={isLoad} formik={formik} handleLogout={handleLogout} />
                <PaymentMethod handleClick={handleClick} selected={selected} />
                <PlaceOrder formik={formik} selected={selected} />
              </Styles.PageContent>
              <Styles.PlaceOrderContainer>
                <CustomButton
                  disabled={
                    cartVs.length == 0 ||
                    selected == 100 ||
                    (remember_cart_login?.userName != undefined
                      ? false
                      : formik.values.emailAddress == ''
                      ? true
                      : false)
                  }
                  onClick={async () => {
                    const errors = await formik.validateForm();
                    if (remember_cart_login?.userName == undefined) {
                      formik.setFieldTouched('emailAddress', true);
                      if (errors.emailAddress) {
                        return scrollToSection('cart-login');
                      }
                      if (!validation(formik.values.password)) {
                        document.getElementById('password').focus();
                        return scrollToSection('cart-login');
                      }
                    }
                    handleOrder(formik.values);
                  }}
                  isLoading={isLoading}
                  title={t(title)}
                  type="submit"
                  buttonStyles={{ padding: '0.5rem 2rem' }}
                />
              </Styles.PlaceOrderContainer>
            </Styles.PageContainer>
          )}
        </Formik>
      </Styles.PageHeaderBodyContainer>
    </Styles.MemberNavigatorSC>
  );
};

export default Checkout;
