import React from 'react';
import * as Styles from './ServerSizeCard.style';
import { useTranslation, Trans } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { createFeatures } from '../../util';
import { Highlight, HighlightBackground } from 'components/common';
import { P } from 'components/reusable-css';
import { Message } from 'components/alert/Message';

const ServerSizeCard = (props) => {
  const { t } = useTranslation();
  const { data, selected = false, formikProp } = props;
  const { values, setFieldValue } = formikProp;
  const isStockAvailable = data?.stock?.find((val) => val.region == values.region);
  const isSale = Number(data.price_monthly_usd) - Number(data.nonsale_price_monthly_usd);
  const isRegionIsAvailable = (serverSize) => serverSize.availableregions.includes(values.region);
  
  const handleClick = (value) => {
    if (!isRegionIsAvailable(value)) {
      Message.error(t('alert4'), t('alert5'));
      return;
    }
    if (isStockAvailable.stock == 0) {
      return;
    }
    let term = value.price_monthly_usd == '' ? 12 : 1;
    setFieldValue('term', term);
    setFieldValue('size', value.id);
    setFieldValue('monthly_sizePrice', value.price_monthly_usd);
    setFieldValue('yearly_sizePrice', value.price_yearly_usd);
    setFieldValue('biennial_sizePrice', value.price_biennial_usd);
    setFieldValue('triennial_sizePrice', value.price_triennial_usd);
    setFieldValue('selected_size', value);
    setFieldValue('app', '');
    setFieldValue('monthly_appPrice', 0);
    setFieldValue('yearly_appPrice', 0);
    setFieldValue('biennial_appPrice', 0);
    setFieldValue('triennial_appPrice', 0);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Styles.GreyOut isGrey={isStockAvailable?.stock == 0 || !isRegionIsAvailable(data)}>
        <Styles.ServerSizeCardSC selected={selected} onClick={() => handleClick(data)}>
          <Styles.MobileTag showTag={Number(data.mostpopular) || isSale}>
            {Number(data.mostpopular)
              ? t('public/checkout/vs.step2_string3')
              : isSale
              ? t('public/checkout/vs.step2_string4')
              : null}
          </Styles.MobileTag>
          <Styles.Tag showTag={Number(data.mostpopular) || isSale}>
            {Number(data.mostpopular)
              ? t('public/checkout/vs.step2_string3')
              : isSale
              ? t('public/checkout/vs.step2_string4')
              : null}
            {isSale ? (
              <Styles.ActualPrice>
                <Trans
                  i18nKey="public/checkout/vs.step2_string5"
                  values={{
                    currency_symbol: '$',
                    price: data.nonsale_price_monthly_usd,
                  }}
                />
              </Styles.ActualPrice>
            ) : null}
          </Styles.Tag>

          <Styles.FlexCon>
            <Styles.NameContainer>
              <Styles.Name>{data.id.toUpperCase()}</Styles.Name>
            </Styles.NameContainer>
            {data?.billing_cycle == 'monthly' ? (
              data.price_monthly_usd == '' ? (
                <Styles.PriceNoMonth>
                  <Trans
                    i18nKey="public/checkout/vs.step2_string24"
                    values={{
                      yearly_price: data.price_yearly_usd,
                      currency: 'USD',
                      currency_symbol: '$',
                    }}
                    components={{
                      price: <Styles.PriceUnit />,
                      monthly: <Styles.PerMonth />,
                      currency: <Styles.Currency />,
                    }}
                  />
                </Styles.PriceNoMonth>
              ) : (
                <Styles.Price>
                  <Trans
                    i18nKey="public/checkout/vs.step2_string6"
                    values={{
                      monthly_price: Math.round(data.price_monthly_usd),
                      yearly_price: data.price_yearly_usd,
                      currency: 'USD',
                      currency_symbol: '$',
                    }}
                    components={{
                      price: <Styles.PriceUnit />,
                      monthly: <Styles.PerMonth />,
                      currency: <Styles.Currency />,
                      hourly: <Styles.PerHour />,
                    }}
                  />
                </Styles.Price>
              )
            ) : (
              <Styles.Price>
                <Trans
                  i18nKey="public/checkout/vs.step2_string7"
                  values={{
                    monthly_price: Math.round(data.price_monthly_usd),
                    hourly_price: data.price_hourly_usd,
                    currency: 'USD',
                    currency_symbol: '$',
                  }}
                  components={{
                    price: <Styles.PriceUnit />,
                    monthly: <Styles.PerMonth />,
                    currency: <Styles.Currency />,
                    hourly: <Styles.PerHour />,
                  }}
                />
              </Styles.Price>
            )}
          </Styles.FlexCon>

          <Styles.Features>
            {createFeatures.map(
              (feature) =>
                ((feature.lanRef == 'ddos_protection' && data[feature.dataKey] == 1) ||
                  feature.lanRef != 'ddos_protection') && (
                  <Styles.Feature key={feature.id}>
                    <Icon name="check" />
                    <P>
                      <Trans
                        i18nKey={
                          feature.lanRef == 'cpu' && data.vcpugen == 'xeongold'
                            ? data[feature.dataKey] > 1
                              ? 'public/checkout/vs.step2_string9'
                              : 'public/checkout/vs.step2_string8'
                            : feature.lanRef == 'cpu' && data.vcpugen == 'xeone5v4'
                            ? data[feature.dataKey] > 1
                              ? 'public/checkout/vs.step2_string12'
                              : 'public/checkout/vs.step2_string10'
                            : data[feature.dataKey] > 1
                            ? feature.lang_plural
                            : feature.lang
                        }
                        values={{
                          count:
                            feature.id == 4 ? Number(data[feature.dataKey]).toLocaleString() : data[feature.dataKey],
                        }}
                        components={{ highlight: <Highlight /> }}
                      />
                    </P>
                  </Styles.Feature>
                ),
            )}
            <Styles.Feature>
              <Icon name="check" />
              {isStockAvailable?.stock == 0 ? (
                <Trans
                  i18nKey={'public/checkout/vs.step2_string22'}
                  components={{ highlight: <HighlightBackground isError /> }}
                />
              ) : (
                <Trans
                  i18nKey={'public/checkout/vs.step2_string21'}
                  components={{ highlight: <HighlightBackground /> }}
                />
              )}
            </Styles.Feature>
          </Styles.Features>

          {selected && (
            <Styles.SelectedTag>
              <Icon name="check" />
              {t('public/checkout/vs.step2_string23')}
            </Styles.SelectedTag>
          )}
          {selected && (
            <Styles.SelectedMobileTag>
              <Icon name="check" />
            </Styles.SelectedMobileTag>
          )}
        </Styles.ServerSizeCardSC>
      </Styles.GreyOut>
    </div>
  );
};

export default ServerSizeCard;
