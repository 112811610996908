import WindowOSImage from 'assets/images/os_windows.svg';
import CentOSImage from 'assets/images/os_centos.svg';
import DebianOSImage from 'assets/images/os_debian.svg';
import FedoraOSImage from 'assets/images/os_fedora.svg';
import UbuntuOSImage from 'assets/images/os_ubuntu.svg';
import LinuxOSImage from 'assets/images/os_almalinux.svg';
import RockyOSLinux from 'assets/images/os_RockyLinux.svg';
import * as Yup from 'yup';

export const createFeatures = [
  {
    id: 1,
    dataKey: 'vcpus',
    lanRef: 'cpu',
    lang: 'public/checkout/vs.step2_string17',
    lang_plural: 'public/checkout/vs.step2_string18',
  },
  {
    id: 2,
    dataKey: 'memory_gb',
    lanRef: 'ram',
    lang: 'public/checkout/vs.step2_string13',
    lang_plural: 'public/checkout/vs.step2_string14',
  },
  {
    id: 3,
    dataKey: 'disk_gb',
    lanRef: 'ssd',
    lang: 'public/checkout/vs.step2_string15',
    lang_plural: 'public/checkout/vs.step2_string16',
  },
  {
    id: 4,
    dataKey: 'datatransfer_gb',
    lanRef: 'data_transfer',
    lang: 'public/checkout/vs.step2_string19',
    lang_plural: 'public/checkout/vs.step2_string20',
  },
  {
    id: 5,
    dataKey: 'bandwidth_gbps',
    lanRef: 'bandwidth_gbps',
    lang: 'public/checkout/vs.step2_string25',
    lang_plural: 'public/checkout/vs.step2_string25',
  },
  {
    id: 6,
    dataKey: 'ipv4',
    lanRef: 'ipv4',
    lang: 'public/checkout/vs.step2_string26',
    lang_plural: 'public/checkout/vs.step2_string26',
  },
  {
    id: 7,
    dataKey: 'private_vlan',
    lanRef: 'private_vlan',
    lang: 'public/checkout/vs.step1_string29',
    lang_plural: 'public/checkout/vs.step1_string29',
  },
  {
    id: 8,
    dataKey: 'ddos_protection',
    lanRef: 'ddos_protection',
    lang: 'public/checkout/vs.step2_string27',
    lang_plural: 'public/checkout/vs.step2_string27',
  },
];

export const totalPrice = (values) =>
  Number(values.monthly_sizePrice) +
  Number(values.monthly_osPrice) +
  Number(values.monthly_appPrice) +
  Number(values.monthly_backupPrice);

export const yearlyTotalPrice = (values) =>
  Number(values.yearly_sizePrice) +
  Number(values.yearly_backupPrice) +
  Number(values.yearly_osPrice) +
  Number(values.yearly_appPrice);

export const biYearlyTotalPrice = (values) =>
  Number(values.biennial_backupPrice) +
  Number(values.biennial_osPrice) +
  Number(values.biennial_sizePrice) +
  Number(values.biennial_appPrice);
  
export const triYearlyTotalPrice = (values) =>
  Number(values.triennial_backupPrice) +
  Number(values.triennial_osPrice) +
  Number(values.triennial_sizePrice) +
  Number(values.triennial_appPrice);

export const hoursTotalPrice = (values) =>
  (Number(values.monthly_sizePrice) +
    Number(values.monthly_backupPrice) +
    Number(values.monthly_osPrice) +
    Number(values.monthly_appPrice)) /
  672;

export const initialValues = (cart_selected_region) => {
  return {
    region: cart_selected_region?.id || 'ash1-1',
    size: '',
    app: '',
    image: '',
    selected_region: cart_selected_region,
    selected_size: {},
    selected_image: {},
    monthly_sizePrice: 0,
    monthly_osPrice: 0,
    monthly_appPrice: 0,
    monthly_backupPrice: 0,
    yearly_sizePrice: 0,
    yearly_osPrice: 0,
    yearly_appPrice: 0,
    yearly_backupPrice: 0,
    term: 1,
    backup: 'plan0',
    biennial_sizePrice: 0,
    biennial_osPrice: 0,
    biennial_appPrice: 0,
    biennial_backupPrice: 0,
    triennial_sizePrice: 0,
    triennial_osPrice: 0,
    triennial_appPrice: 0,
    triennial_backupPrice: 0,
  };
};

export const validationSchema = Yup.object({
  region: Yup.string().required(),
  size: Yup.string().required(),
  app: Yup.string(),
  image: Yup.string(),
  backup: Yup.string().required(),
});

export const initialValuesLogin = {
  emailAddress: '',
  selected: 100,
  password: '',
};

export const validationSchemaLogin = Yup.object({
  emailAddress: Yup.string().email('Invalid email address'),
});

export const getOSImage = (name) => {
  switch (name) {
    case 'windows':
      return WindowOSImage;
    case 'centos':
      return CentOSImage;
    case 'debian':
      return DebianOSImage;
    case 'fedora':
      return FedoraOSImage;
    case 'ubuntu':
      return UbuntuOSImage;
    case 'almalinux':
      return LinuxOSImage;
    case 'rockylinux':
      return RockyOSLinux;
    default:
      return '';
  }
};

export const osPricing = (price) =>
  Number(price?.price_monthly_usd) > 0 ? `($${Number(price?.price_monthly_usd)}/mo)` : null;
